import { Link } from 'gatsby-plugin-react-i18next';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import YouTube from 'react-youtube';
import * as React from 'react';
export default {
  Link,
  getImage,
  GatsbyImage,
  YouTube,
  React
};